import {Col, Container, Row} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg"
import {useEffect, useState} from "react";

import 'animate.css'
import TrackVisibility from "react-on-screen";

export const Banner = () => {
    const toRotate = ["Web Developper", "Web Designer", "Full stack developper"]

    const [loopNum, setLoopNum] = useState(0)
    const [isDeleting, setIsDeleting] = useState(false)
    const [text, setText] = useState("")
    const [delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick()
        }, delta)

        return () => { clearInterval(ticker) }
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText)

        if (isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true)
            setDelta(period)
        } else if (isDeleting && updatedText === '') {
            setIsDeleting(false)
            setLoopNum(loopNum + 1)
            setDelta(300)
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                            {({isVisible}) =>
                        <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                            <span className="tagline">Welcome to my Portfolio</span>
                            <h1>{`Je m'appelle Jeremy`}<span className="wrap"> {text}</span></h1>
                            <p>
                                Actuellement en bachelor en Ingénierie du web j'ai 3 ans d'expériences dans le web. Je vous laisse à travers ce portfolio découvrir mon experience dans le domaine.
                            <br/>
                            Si vous souhaitez un devis contactez moi
                            </p>
                            <button onClick={() => console.log('connect')}><a href="#contact" className="link-a">Contactez moi <ArrowRightCircle size={25}/></a></button>
                        </div>}
                        </TrackVisibility>
                    </Col>

                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Header Img"/>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}